<hypecast-admin-dialog-header (emitCloseClick)="dialogRef.close(null)" class="dialog-header"
                              title="You have pending changes">
</hypecast-admin-dialog-header>

<div style="margin-top: 24px; min-width: 500px; margin-bottom: 50px;">
  There are unsaved changes.
  <br>
  <br>
  Do you want to continue without saving?
</div>

<hypecast-admin-dialog-footer>
  <div class="flex flex-row">
    <hypecast-admin-buttons-cancel (onClick)="dialogRef.close(null)" class="flex-initial">Go back
    </hypecast-admin-buttons-cancel>
    <hypecast-admin-buttons-submit (onClick)="dialogRef.close(true)" class="ml-4 flex-initial">Continue without saving
    </hypecast-admin-buttons-submit>
  </div>

</hypecast-admin-dialog-footer>
